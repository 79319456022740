import React, { Component } from "react";
import styled from "styled-components";

import Helmet from "react-helmet";

import { navigate } from "gatsby";

import Label from "components/Elements/Label";
import Button from "components/Elements/Button";

import AuthUserContext from "../components/Session/AuthUserContext";
import withAuthorization from "../components/Session/withAuthorization";

import { auth } from "api/firebase";
import {
  pushSubmission,
  fetchUserDataByUid,
  loadSavedSubmission,
  updateSubmission,
} from "api/db";

import { Row, Col } from "react-grid-system";

import Markdown from "react-remarkable-with-plugins";
import hljs from "highlight.js";

import { Project, ArticleWrapper } from "components/Post/styles";
import HeaderLivePreview from "components/Post/HeaderLivePreview";

const Banner = styled.a`
  max-width: 100%;
  text-align: center;
  background: linear-gradient(to right, #4b6cb7, #182848);
  padding: 9px 9px;
  font-size: 18px;
  font-weight: 500;
  color: white;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  transition: all 300ms ease;
  &:hover {
    opacity: 0.6;
    background-position: right center;
  }
`;

const Container = styled.div`
  font-size: 20px;
  margin: auto;
  max-width: 95%;
  padding: 32px;
`;

const PreviewContainer = styled.div`
  max-width: 50em;
  margin: 0 auto;
`;

const updateByPropertyName = (propertyName, value) => () => ({
  [propertyName]: value,
});

const highlight = (str, lang) => {
  if (lang && hljs.getLanguage(lang)) {
    try {
      return hljs.highlight(lang, str).value;
    } catch (err) {
      console.error(err);
    }
  }

  try {
    return hljs.highlightAuto(str).value;
  } catch (err) {
    console.error(err);
  }

  return "";
};

class SubmitPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      uid: "",
      username: "",
      email: "",
      title: "Project Title",
      description: "Project Description",
      language: "Language",
      difficulty: "Difficulty",
      category: "Category",
      imageURL: "https://enlight.nyc/img/logo.jpg",
      demoURL: "",
      sourceURL: "",
      content:
        "# Share your content with the world on Enlight \n Hey there! You can edit this by typing in the form fields above! Submit when you are ready for your project submission to be reviewed and published. This page automatically saved, so don't worry about having to manually save your progress!",
      date: "",
      projectId: "",
      published: false,
      submitted: false,
      key: "",
      tutorialSubmissionStarted: false,
    };
  }

  componentDidMount() {
    let displayName = auth.currentUser.displayName;
    let email = auth.currentUser.email;
    let uid = auth.currentUser.uid;

    let d = new Date();
    let date = d.getDate();
    let month = d.getMonth();
    let year = d.getFullYear();

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let dateStr = monthNames[month] + " " + date + ", " + year;

    this.setState(
      {
        name: displayName,
        uid: uid,
        email: email,
        date: dateStr,
      },
      () => {
        // callback so that uid is set when this is called
        fetchUserDataByUid(uid).on(
          "value",
          function(snapshot) {
            let data = snapshot.val();
            this.setState(
              {
                username: data.id,
              },
              () => {
                // callback so that state is set when this is called
                this.loadSavedSubmission(uid);
              }
            );
          }.bind(this)
        );
      }
    );
  }
  loadSavedSubmission = (uid) => {
    loadSavedSubmission(uid).then((snapshot) => {
      let data = snapshot.val();
      if (data) {
        data = Object.entries(data);
        data.forEach((submission) => {
          if (submission[1].submitted == false) {
            this.setState({ tutorialSubmissionStarted: true });
            let key = submission[0];
            submission = submission[1];
            this.setState({
              title: submission.title,
              description: submission.description,
              language: submission.language,
              difficulty: submission.difficulty,
              category: submission.category,
              imageURL: submission.img,
              demoURL: submission.demoURL,
              sourceURL: submission.sourceURL,
              content: submission.content,
              projectId: submission.id,
              key: key,
            });
          }
        });
        if (this.state.tutorialSubmissionStarted == false) {
          // case where user has 1 or more submitted tutorials
          pushSubmission(
            this.state.name,
            this.state.uid,
            this.state.username,
            this.state.email,
            this.state.title,
            this.state.description,
            this.state.language,
            this.state.difficulty,
            this.state.category,
            this.state.imageURL,
            this.state.demoURL,
            this.state.sourceURL,
            this.state.content,
            this.state.date,
            this.state.projectId,
            this.state.published,
            false
          ).then(this.loadSavedSubmission(this.state.uid));
        }
      } else {
        pushSubmission(
          this.state.name,
          this.state.uid,
          this.state.username,
          this.state.email,
          this.state.title,
          this.state.description,
          this.state.language,
          this.state.difficulty,
          this.state.category,
          this.state.imageURL,
          this.state.demoURL,
          this.state.sourceURL,
          this.state.content,
          this.state.date,
          this.state.projectId,
          this.state.published,
          false
        ).then(this.loadSavedSubmission(this.state.uid));
      }
    });
  };

  onSubmit = (event) => {
    event.preventDefault();
    this.setState(
      {
        submitted: true,
      },
      () => {
        this.updateSubmission();
        navigate("/success");
      }
    );
  };

  updateSubmission = () => {
    if (this.state.tutorialSubmissionStarted) {
      updateSubmission(
        this.state.name,
        this.state.uid,
        this.state.username,
        this.state.email,
        this.state.title,
        this.state.description,
        this.state.language,
        this.state.difficulty,
        this.state.category,
        this.state.imageURL,
        this.state.demoURL,
        this.state.sourceURL,
        this.state.content,
        this.state.date,
        this.state.projectId,
        this.state.published,
        this.state.key,
        this.state.submitted
      );
    }
  };
  render() {
    return (
      <div>
        <Helmet title="Submit a Project | Enlight" />

        <div className="max-w-lg mx-auto mt-4">
          <a href="https://www.notion.so/Content-Guidelines-d704bbc275484a07b618af54bdf05e26">
            <div class="bg-blue-100 text-blue-700 px-4 py-3" role="alert">
              <p class="font-bold">Content Guidelines</p>
              <p class="text-sm">
                {" "}
                &#8249; If you have any questions, check out our content
                guidelines here! &#8250;
              </p>
            </div>
          </a>
        </div>
        <AuthUserContext.Consumer>
          {(authUser) => (
            <div>
              <Container>
                <Label>Submit a Project</Label>
                <br />
                <form onSubmit={this.onSubmit}>
                  <Row>
                    <Col>
                      <div className="input-submit">
                        <input
                          className="large input-center"
                          onChange={(event) => {
                            let data = event.target.value;
                            let id = data.replace(/\s+/g, "-").toLowerCase();
                            this.setState(
                              {
                                title: event.target.value,
                                projectId: id,
                              },
                              () => {
                                this.updateSubmission();
                              }
                            );
                          }}
                          type="text"
                          placeholder="Project Title"
                          value={this.state.title}
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="input-submit">
                        <input
                          className="large input-center"
                          onChange={(event) => {
                            this.setState(
                              updateByPropertyName(
                                "description",
                                event.target.value
                              ),
                              () => {
                                this.updateSubmission();
                              }
                            );
                          }}
                          type="text"
                          placeholder="Project Description"
                          value={this.state.description}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="input-submit">
                        <input
                          className="large input-center"
                          onChange={(event) => {
                            this.setState(
                              updateByPropertyName(
                                "language",
                                event.target.value
                              ),
                              () => {
                                this.updateSubmission();
                              }
                            );
                          }}
                          type="text"
                          placeholder="Project Language"
                          value={this.state.language}
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="input-submit">
                        <select
                          className="large input-center"
                          onChange={(event) => {
                            this.setState(
                              updateByPropertyName(
                                "category",
                                event.target.value
                              ),
                              () => {
                                this.updateSubmission();
                              }
                            );
                          }}
                          type="text"
                          placeholder="Project Category: Web, ML, etc" // placeholder text no longer shows
                          value={this.state.category}
                        >
                          <option selected value disabled>
                            Project Category
                          </option>
                          <option>Web Development</option>
                          <option>Data Science</option>
                          <option>Mobile</option>
                          <option>Other</option>
                        </select>
                      </div>
                    </Col>
                    <Col>
                      <div className="input-submit">
                        <select
                          className="large input-center"
                          onChange={(event) => {
                            this.setState(
                              updateByPropertyName(
                                "difficulty",
                                event.target.value
                              ),
                              () => {
                                this.updateSubmission();
                              }
                            );
                          }}
                          type="text"
                          placeholder="Project Difficulty: Beginner, Intermediate, Hard"
                          value={this.state.difficulty}
                        >
                          <option selected value disabled>
                            Project Difficulty
                          </option>
                          <option>Beginner</option>
                          <option>Intermediate</option>
                          <option>Advanced</option>
                        </select>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <div className="input-submit">
                        <input
                          className="large input-center"
                          onChange={(event) => {
                            this.setState(
                              updateByPropertyName(
                                "imageURL",
                                event.target.value
                              ),
                              () => {
                                this.updateSubmission();
                              }
                            );
                          }}
                          type="text"
                          placeholder="Project Image Link"
                          value={this.state.imageURL}
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="input-submit">
                        <input
                          className="large input-center"
                          onChange={(event) => {
                            this.setState(
                              updateByPropertyName(
                                "demoURL",
                                event.target.value
                              ),
                              () => {
                                this.updateSubmission();
                              }
                            );
                          }}
                          type="text"
                          placeholder="Project Demo Link"
                          value={this.state.demoURL}
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="input-submit">
                        <input
                          className="large input-center"
                          onChange={(event) => {
                            this.setState(
                              updateByPropertyName(
                                "sourceURL",
                                event.target.value
                              ),
                              () => {
                                this.updateSubmission();
                              }
                            );
                          }}
                          type="text"
                          placeholder="GitHub Repo Link"
                          value={this.state.sourceURL}
                        />
                      </div>
                    </Col>
                  </Row>

                  <textarea
                    className="submission"
                    onChange={(event) => {
                      this.setState(
                        updateByPropertyName("content", event.target.value),
                        () => {
                          this.updateSubmission();
                        }
                      );
                    }}
                    type="text"
                    value={this.state.content}
                  />

                  <HeaderLivePreview
                    title={this.state.title}
                    description={this.state.description}
                    writer={this.state.name}
                    writerId={this.state.username}
                    category={this.state.category}
                    language={this.state.language}
                    difficulty={this.state.difficulty}
                    date={this.state.date}
                    id={this.state.id}
                    img={this.state.imageURL}
                    source={this.state.sourceURL}
                    demourl={this.state.demoURL}
                  />

                  <PreviewContainer>
                    <ArticleWrapper>
                      <Project className="text-gray-800">
                        <Markdown options={{ highlight, html: true }}>
                          {this.state.content}
                        </Markdown>
                      </Project>
                    </ArticleWrapper>
                  </PreviewContainer>

                  <br />

                  <Button type="submit">Submit Project</Button>
                </form>
              </Container>
            </div>
          )}
        </AuthUserContext.Consumer>
      </div>
    );
  }
}
const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(SubmitPage);
